<template>
  <div>
    <vue-editor v-model="content" @input="$emit('input', content)"></vue-editor>
  </div>
</template>

<script>
import { VueEditor } from "vue2-editor";

export default {
  components: {
    VueEditor
  },
  props: ["content"],
  data() {
    return {
      
    };
  }
};
</script>
<style lang="scss">
.ql-toolbar{
  position: sticky !important;
  top: 0 !important;
  background: #fff !important;
  z-index: 111;
}
</style>