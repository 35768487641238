<template>
    <CriarGadget />
</template>

<script>
import CriarGadget from '@/components/Gadget/CriarGadget'
export default {
  components: {CriarGadget},
  data(){
    return{}
  }
}
</script>

<style>

</style>