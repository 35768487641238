<template>
  <v-container>
    <div class="header">
      <span>
        <h3>Criar um novo gadget</h3>
        <p style="text-align:left">Um gadget por ser do tipo formulário ou contrato. Selecione a opção que deseja criar</p>
      </span>
      <div class="grow"/>
      <button type="button" class="btn-voltar" @click="$router.push('/lista/gadget')">voltar</button>
    </div>
    <div class="button-group">
      <button type="button" @click="() => selecionarTipoGadget('form')">Formulário</button>
      <button type="button" @click="() => selecionarTipoGadget('contract')">Contrato</button>
    </div>
    <section class="wrapper">
      <div class="wrapper-form" v-if="currgadgettipo==='form'">
        <div style="display: flex;flex-wrap:wrap">
          <v-col
            cols="12"
            sm="3" 
            xs="12" 
            md="3"
          >
            <v-text-field
              v-model="nome"
              name="nome"
              label="Nome do gadget"
              solo
              clearable
              @mousedown="(e) => limparErrosVtext(e)"
              :error-messages="erros.nome"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            xs="12"
            md="9"
          >
            <v-text-field
              v-model="descricao"
              label="Desccrição do gadget"
              solo
              clearable
              @mousedown="(e) => limparErrosVtext(e)"
              :error-messages="erros.descricao"
              name="descricao"
            ></v-text-field>
          </v-col>
        </div>
        <div>
          <button type="button" class="btn-nova-pergunta" @click="novapergunta=true">nova pergunta</button>
          <div class="wrapper-tipos-perguntas" v-if="novapergunta">
            <button type="button" @click="() => selecionarTipoPergunta('fechada')">Resposta Fechada</button>
            <button type="button" @click="() => selecionarTipoPergunta('aberta')">Resposta Aberta</button>
            <button type="button" @click="() => selecionarTipoPergunta('data')">Resposta data</button>
          </div>
        </div>
        <div class="wrapper-perguntas">
          <div 
              v-for="pergunta, index in perguntas" 
              :key="index" 
              :id="`wrapper-pergunta-${index}`" 
              class="wrapper-pergunta"
              @click="() => limparErros(index)"
            >
            <div style="display: flex; justify-content: flex-end; align-items: center;">
              <p style="margin-right: .5em; font-size: 12px; color:#ccc; font-weight: bold;">
                {{pergunta.obrigatoria ? 'Obrigatória' : 'Não Obrigatória'}}</p>
              <v-switch
                v-model="pergunta.obrigatoria"
                inset
                dense
                class="btn-obrigatoria"
                hide-details
              ></v-switch>
              <v-select
                v-if="pergunta.tipo === 'aberta'"
                :items="mascaras"
                label="Máscara"
                name="mask"
                v-model="pergunta.mask"
                outlined
                dense
                hide-details
              ></v-select>
            </div>
            <div style="display: flex; align-items: center;justify-content: flex-start;margin-bottom: .5em;">
              <button type="button" style="margin-right: 1em;" @click="() => deletarPergunta(pergunta)"><v-icon color="#F23E2C">mdi-close-circle</v-icon></button>
              <div class="field" v-if="pergunta.tipo === 'fechada'">
                <label>Pergunta:</label>
                <input v-model="pergunta.titulo" type="text" class="input-titulo" placeholder="Insira a pergunta"/>
                
              </div>
              <div class="field" v-if="pergunta.tipo === 'aberta'">
                <label>Pergunta:</label>
                <textarea placeholder="Insira a pergunta" v-model="pergunta.titulo" style="width: 100%"></textarea>
              </div>
              <div class="field" v-if="pergunta.tipo === 'data'">
                <label>Pergunta:</label>
                <textarea placeholder="Insira a pergunta" v-model="pergunta.titulo" style="width: 100%"></textarea>
              </div>
              <button type="button" v-if="pergunta.tipo === 'fechada'" class="btn-nova-opcao" @click="() => inserirOpcaoPergunta(pergunta)">+ opção</button>
            </div>
                  
            <div v-if="pergunta.tipo === 'fechada'">
              <div v-for="opcao, idx in pergunta.opcoes" :key="idx" class="wrapper-opcao">
                <div class="opcao-content">
                  <input type="radio" disabled/>
                  <input type="text" v-model="opcao.texto" class="input-opcao" placeholder="Insira o a resposta da pergunta"/>
                  <button @click="() => vincular(pergunta, idx)" v-if="!vinculos.includes(pergunta.id+idx)"><v-icon>mdi-link</v-icon></button>
                  <button @click="() => desvincular(pergunta, idx)" v-if="vinculos.includes(pergunta.id+idx)"><v-icon>mdi-link-off</v-icon></button>
                  <v-select
                    class="select-vincular"
                    v-if="pergunta.opcoes[idx].texto === respostaselecionada.texto || vinculos.includes(pergunta.id+idx)"
                    :items="perguntasvincular(pergunta)"
                    label="Próxima Pergunta"
                    dense
                    outlined
                    hide-details
                    v-model="opcao.transicao"
                  ></v-select>  
                  <button class="btn-perguntas-extras" @click="() => abrirPerguntasExtras(pergunta, opcao.id)">
                    <v-icon>mdi-more</v-icon>
                  </button>
                </div>    
              </div>
            </div>
          </div>
        </div>
        <div>
          <v-btn
            v-if="perguntas.length > 0"
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submetergadget">
            criar gadget
          </v-btn>
        </div>
      </div>    
      <div class="wrapper-contract" v-if="currgadgettipo==='contract'">
        <div style="display: flex;flex-wrap:wrap">
          <v-col
            cols="12"
            sm="3"
            xs="12"
            md="3"
          >
            <v-text-field
              v-model="nome"
              label="Nome do gadget"
              solo
              clearable
              :error-messages="erros.nome"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            sm="9"
            xs="12"
            md="9"
          >
            <v-text-field
              v-model="descricao"
              label="Desccrição do gadget"
              solo
              clearable
              :error-messages="erros.descricao"
            ></v-text-field>
          </v-col>
        </div>
        <Contrato :content="contract" v-model="contract"/>
        <div>
          <v-btn
            v-if="contract && contract.length > 0"
            elevation="0"
            :ripple="false"
            class="btn"
            data-cy="btn-envio"
            color="#1377F2"
            type="submit"
            :disabled="reqloading"
            :loading="reqloading"
            @click="submetergadget">
            criar gadget
          </v-btn>
        </div>
      </div>      
    </section>
    <div class="modal-perguntasextras" v-if="abrirmodalpergextra">
        <div class="wrapper-perguntas">
          <div style="display: flex; align-items:center; width: 100%">
            <button type="button" class="btn-nova-pergunta elevation" @click="novaperguntaextra=true">nova pergunta</button>
            <div class="wrapper-tipos-perguntas" v-if="novaperguntaextra">
              <button type="button" @click="() => selecionarTipoPerguntaExtra('fechada', perguntaatual.id, opcaoatual)">Resposta Fechada</button>
              <button type="button" @click="() => selecionarTipoPerguntaExtra('aberta', perguntaatual.id, opcaoatual)">Resposta Aberta</button>
              <button type="button" @click="() => selecionarTipoPerguntaExtra('data', perguntaatual.id, opcaoatual)">Resposta data</button>
            </div>
            <div style="flex:1;"/>
            <button style="align-self: flex-end" @click="() => fecharPerguntasExtras()">
              <v-icon color="#F23E2C">mdi-close-circle</v-icon>
            </button>
          </div>
          <div 
              v-for="pergunta, index in perguntasextrascomp" 
              :key="index" 
              :id="`wrapper-pergunta-extra-${index}`" 
              class="wrapper-pergunta"
              @click="() => limparErros(index)"
            >
            <div style="display: flex; justify-content: flex-end; align-items: center;">
              <p style="margin-right: .5em; font-size: 12px; color:#ccc; font-weight: bold;">
                {{pergunta.obrigatoria ? 'Obrigatória' : 'Não Obrigatória'}}</p>
              <v-switch
                v-model="pergunta.obrigatoria"
                inset
                dense
                class="btn-obrigatoria"
                hide-details
              ></v-switch>
              <v-select
                v-if="pergunta.tipo === 'aberta'"
                :items="mascaras"
                label="Máscara"
                name="mask"
                v-model="pergunta.mask"
                outlined
                dense
                hide-details
              ></v-select>
            </div>
            <div style="display: flex; align-items: center;justify-content: flex-start;margin-bottom: .5em;">
              <button type="button" style="margin-right: 1em;" @click="() => deletarPerguntaExtra(pergunta)"><v-icon color="#F23E2C">mdi-close-circle</v-icon></button>
              <div class="field" v-if="pergunta.tipo === 'fechada'">
                <label>Pergunta:</label>
                <input v-model="pergunta.titulo" type="text" class="input-titulo" placeholder="Insira a pergunta"/>
              </div>
              <div class="field" v-if="pergunta.tipo === 'aberta'">
                <label>Pergunta:</label>
                <textarea placeholder="Insira a pergunta" v-model="pergunta.titulo" style="width: 100%"></textarea>
              </div>
              <div class="field" v-if="pergunta.tipo === 'data'">
                <label>Pergunta:</label>
                <textarea placeholder="Insira a pergunta" v-model="pergunta.titulo" style="width: 100%"></textarea>
              </div>
              <button type="button" v-if="pergunta.tipo === 'fechada'" class="btn-nova-opcao" @click="() => inserirOpcaoPerguntaExtra(pergunta)">+ opção</button>
            </div>
                  
            <div v-if="pergunta.tipo === 'fechada'">
              <div v-for="opcao, idx in pergunta.opcoes" :key="idx" class="wrapper-opcao">
                <div class="opcao-content">
                  <input type="radio" disabled/>
                  <input type="text" v-model="opcao.texto" placeholder="Insira o a resposta da pergunta"/>
                </div>    
              </div>
            </div>
          </div>
        </div>
    </div>
    
  </v-container>

</template>

<script>
import GadgetServico from '@/services/gadget.service.js'
import Contrato from '@/components/Gadget/Contrato'
import {alertar} from '@/services/notificacao.service.js'


export default {
  components: {Contrato},
  data(){
    return {
      contador:1,
      reqloading: false,
      currgadgettipo: null,
      novapergunta: false,
      perguntas: [],
      perguntasextras: [],
      nome: null,
      descricao: null,
      contract: null,
      erros: {
        nome: null,
        descricao: null
      },
      respostaselecionada: {texto: null},
      vinculos: [],
      abrirmodalpergextra: false,
      novaperguntaextra: false,
      perguntaatual: {},
      opcaoatual: null,
      contadorpergunta: 0,
      contadorperguntaextra: 0,
      mascaras: ['data', 'cpf', 'cnpj', 'telefone']
    }
  },
  computed: {
    perguntasvincular(){
      return pergunta => this.perguntas.filter(el => el.titulo !== pergunta.titulo).map(item=> item.titulo)
    },
    perguntasextrascomp(){
      return this.perguntasextras.filter(el => el.idopcao === this.opcaoatual)
    }   
  },
  methods: {
    abrirPerguntasExtras(pergunta, idopcao){
      this.abrirmodalpergextra = !this.abrirmodalpergextra
      this.perguntaatual = pergunta
      this.opcaoatual = idopcao
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    fecharPerguntasExtras(){
      this.abrirmodalpergextra = !this.abrirmodalpergextra
      this.perguntaatual = {}
      this.opcaoatual = null
    },
    selecionarTipoPergunta(tipo){
      if(tipo === 'fechada'){
        //inserir pergunta
        const estadoantigo = this.perguntas
        estadoantigo.push({tipo: 'fechada', mask: '', obrigatoria: false, opcoes: [], id: this.makeid(5), titulo: '', contador: this.contadorpergunta})
        this.perguntas = estadoantigo 
        this.novapergunta = false  
        this.contadorpergunta += 1      
      }
      if(tipo === 'aberta'){
        //inserir pergunta
        const estadoantigo = this.perguntas
        estadoantigo.push({tipo: 'aberta', mask: '', obrigatoria: false, id: this.makeid(5), titulo: '', contador: this.contadorpergunta})
        this.perguntas = estadoantigo 
        this.novapergunta = false 
        this.contadorpergunta += 1       
      }
      if(tipo === 'data'){
        //inserir pergunta
        const estadoantigo = this.perguntas
        estadoantigo.push({tipo: 'data', mask:'', obrigatoria: false,id: this.makeid(5), titulo: '', contador: this.contadorpergunta})
        this.perguntas = estadoantigo 
        this.novapergunta = false  
        this.contadorpergunta += 1      
      }
    },
    selecionarTipoPerguntaExtra(tipo, idpergunta, idopcao){
      if(tipo === 'fechada'){
        //inserir pergunta
        const estadoantigo = this.perguntasextras
        estadoantigo.push({tipo: 'fechada', opcoes: [], mask:'', obrigatoria: false,id: this.makeid(5), titulo: '', idpergunta: idpergunta, idopcao: idopcao, contador: this.contadorperguntaextra})
        this.perguntasextras = estadoantigo 
        this.novaperguntaextra = false 
        this.contadorperguntaextra += 1        
      }
      if(tipo === 'aberta'){
        //inserir pergunta
        const estadoantigo = this.perguntasextras
        estadoantigo.push({tipo: 'aberta', mask:'', obrigatoria: false,id: this.makeid(5), titulo: '', idpergunta: idpergunta, idopcao: idopcao, contador: this.contadorperguntaextra})
        this.perguntasextras = estadoantigo 
        this.novaperguntaextra = false 
        this.contadorperguntaextra += 1        
      }
      if(tipo === 'data'){
        //inserir pergunta
        const estadoantigo = this.perguntasextras
        estadoantigo.push({tipo: 'data', mask:'', obrigatoria: false,id: this.makeid(5), titulo: '', idperguntamae: idpergunta, idopcao: idopcao, contador: this.contadorperguntaextra})
        this.perguntasextras = estadoantigo 
        this.novaperguntaextra = false  
        this.contadorperguntaextra += 1       
      }
    },
    selecionarTipoGadget(tipo){
      this.currgadgettipo = tipo
      this.nome = null
      this.descricao = null
      this.perguntas = []
      this.contract = null
    },
    inserirOpcaoPergunta(pergunta){
      const estadoantigo = this.perguntas.filter(el => el.id === pergunta.id)[0].opcoes
      estadoantigo.push({valor:false, texto: '', transicao: null, id: this.makeid(5)})
      this.perguntas.filter(el => el.id === pergunta.id)[0].opcoes = estadoantigo
    },
    inserirOpcaoPerguntaExtra(pergunta){
      const estadoantigo = this.perguntasextras.filter(el => el.id === pergunta.id)[0].opcoes
      estadoantigo.push({valor:false, texto: '',  id: this.makeid(5)})
      this.perguntasextras.filter(el => el.id === pergunta.id)[0].opcoes = estadoantigo
    },
    salvarPergunta(){
      this.novapergunta = false
    },
    makeid(length) {
      var result = '';
      var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      var charactersLength = characters.length;
      for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      return result;
    },
    async submetergadget(){

      if(this.validacao()){
        this.reqloading = true
        //submeter gadget
        let dadosgadget
        if(this.currgadgettipo=== 'form'){
          dadosgadget = {
            nome: this.nome,
            texto: null,
            idtipo: this.currgadgettipo=== 'form' ? 2 : 1,
            descricao: this.descricao
          }
        }
        if(this.currgadgettipo=== 'contract'){
          dadosgadget = {
            nome: this.nome,
            texto: this.contract,
            idtipo: this.currgadgettipo=== 'form' ? 2 : 1,
            descricao: this.descricao
          }
        }
        let idgadget
        await GadgetServico.criarGadget(dadosgadget)
          .then(async res=> {
            if(res.status === 200){
              idgadget = res.data.ids[0]
              if(this.currgadgettipo=== 'form'){
                let transicoes = []
                let perguntascriadas = []

                this.perguntas = this.perguntas.sort( (a,b) =>{
                  return a.contador > b.contador ? 1 : 0
                        })
                
                for(let x = 0,lenx = this.perguntas.length; x<lenx;x++){
                  const pergunta = this.perguntas[x]
                  if(pergunta.tipo === 'fechada'){
                    let respostas = []
                    for(let k=0;k<pergunta.opcoes.length;k++){
                      let op = pergunta.opcoes[k]
                      let perguntasextras = this.perguntasextras.filter(el => {
                        if(el.idopcao === op.id && el.idpergunta === pergunta.id){
                          return true
                        }
                      })
                      perguntasextras = perguntasextras.sort((a,b) => {
                        return a.contador > b.contador ? 1 : 0
                      })
                      let perguntas = []
                      for(let o=0,leno = perguntasextras.length;o<leno;o++){
                        const pergunta = perguntasextras[o]       
                        if(pergunta.tipo === 'fechada'){
                          const respostas = pergunta.opcoes.map(op => ({resp: op.texto}))     
                          let estado = perguntas
                          estado.push({
                            idgadget: idgadget,
                            pergunta: pergunta.titulo,
                            respostaaberta:false,
                            respostasfechadas: {listaresposta:respostas},
                            ativo:true,
                            obrigatoria: pergunta.obrigatoria,
                          })  
                          perguntas = estado                   
                        }  
                        if(pergunta.tipo === 'aberta'){
                          let estado = perguntas
                          estado.push({
                            idgadget: idgadget,
                            pergunta: pergunta.titulo,
                            respostaaberta:true,
                            respostasfechadas:null,
                            ativo:true,
                            obrigatoria: pergunta.obrigatoria,
                            mascara: pergunta.mask,
                          })
                          perguntas = estado
                        }               
                        if(pergunta.tipo === 'data'){
                          let estado = perguntas
                          estado.push({
                            idgadget: idgadget,
                            pergunta: pergunta.titulo,
                            respostaaberta:false,
                            respostasfechadas:null,
                            respostadata: true,
                            ativo:true,
                            obrigatoria: pergunta.obrigatoria
                          })
                          perguntas = estado
                        }               
                      }
                      respostas.push({resposta: op.texto, perguntasextras:perguntas})
                    }
                    const dadospergunta = {
                      idgadget: idgadget,
                      pergunta: pergunta.titulo,
                      obrigatoria: pergunta.obrigatoria,
                      respostaaberta:false,
                      respostasfechadas: {listaresposta:respostas},
                      ativo:true
                    }
                    //fazer requisicao
                    await GadgetServico.criarPergunta(dadospergunta)
                      .then(res=>{
                        if(res.status === 200){
                          console.log('res fechada', res)
                          let novoestadoperg = perguntascriadas
                          novoestadoperg.push({
                            idpergunta: res.data.perguntagadgetdata.idpergunta,
                            titulo: pergunta.titulo
                          })
                          perguntascriadas = novoestadoperg
                          //cadsatrar transicao da 
                          const trans = pergunta.opcoes.filter(el => el.transicao !== null)
                          if(trans.length > 0){
                            trans.map(opcao => {
                              transicoes.push({
                                idpergunta: res.data.perguntagadgetdata.idpergunta,
                                condicao: `resp == ${opcao.texto}`,
                                tituloproximapergunta: opcao.transicao,
                                idopcaoresposta: res.data.perguntagadgetdata.respostasfechadas.listaresposta.filter(el => el.resposta === opcao.texto).length > 0 && res.data.perguntagadgetdata.respostasfechadas.listaresposta.filter(el => el.resposta === opcao.texto)[0].id
                              })
                            })
                          }
                        }
                      })
                      .catch(error=>{
                        console.log(error)
                      })
                  }
                  if(pergunta.tipo === 'aberta'){
                    const dadospergunta = {
                      idgadget: idgadget,
                      pergunta: pergunta.titulo,
                      respostaaberta:true,
                      respostasfechadas:null,
                      ativo:true,
                      obrigatoria: pergunta.obrigatoria,
                      mascara: pergunta.mask,
                    }
                    //fazer requisicao
                    await GadgetServico.criarPergunta(dadospergunta)
                      .then(res=>{
                        if(res.status === 200){
                          let novoestadoperg = perguntascriadas
                          novoestadoperg.push({
                            idpergunta: res.data.perguntagadgetdata.idpergunta,
                            titulo: pergunta.titulo
                          })
                          perguntascriadas = novoestadoperg
                        }
                      })
                      .catch(error=>{
                        console.log(error)
                      })
                  }
                  if(pergunta.tipo === 'data'){
                    const dadospergunta = {
                      idgadget: idgadget,
                      pergunta: pergunta.titulo,
                      obrigatoria: pergunta.obrigatoria,
                      respostaaberta:false,
                      respostasfechadas:null,
                      respostadata: true,
                      ativo:true,
                    }
                    //fazer requisicao
                    await GadgetServico.criarPergunta(dadospergunta)
                      .then(res=>{
                        if(res.status === 200){
                          let novoestadoperg = perguntascriadas
                          novoestadoperg.push({
                            idpergunta: res.data.perguntagadgetdata.idpergunta,
                            titulo: pergunta.titulo
                          })
                          perguntascriadas = novoestadoperg
                        }
                      })
                      .catch(error=>{
                        console.log(error)
                      })
                  }
                }
                //requsitar transicoes
                transicoes.map(async item => {
                  const idproxperg = perguntascriadas.filter(el => el.titulo === item.tituloproximapergunta)[0].idpergunta
                  const dadostransicao = {
                    idgadgetpergunta: item.idpergunta,
                    idgadgetproximapergunta: idproxperg,
                    condicao: item.condicao,
                    idopcaoresposta: item.idopcaoresposta
                  }
                  console.log('dadostransicao', dadostransicao)
                  await GadgetServico.criarTransicao(dadostransicao).then(() => {
                    console.log('sucesso na criacao da transicao')
                  }).catch(e => console.log('error criacao transicao',e))
                })
                this.nome = null
                this.descricao = null
                this.perguntas = []
                this.perguntasextras = []
                this.contract = null
              }
              if(this.currgadgettipo === 'contract'){
                this.contract = null
                this.nome = null
                this.descricao = null
              }
              this.reqloading = false
              alertar('success', '=)', 'Gadget criado com sucesso')
            }
          })
          .catch(error => {
            console.log(error)
            this.reqloading = false
            alertar('warning', '=/', 'Falha na criação do gadget')
          })
      }

    },
    validacao(){
      let objretorno
      let validado = new Array()
      if(!this.nome || this.nome.trim() === ''){
        validado.push(false)
        this.erros.nome = 'Esse campo é obrigatório'
      }
      if(!this.descricao || this.descricao.trim() === ''){
        validado.push(false)
        this.erros.descricao = 'Esse campo é obrigatório'
      }
      if(this.currgadgettipo === 'form'){
        this.perguntas.map((perg, idx) => {
          if(perg.tipo === 'fechada'){
            if(!perg.titulo || perg.titulo.trim() === ''){
              let haschild = document.querySelector(`#wrapper-pergunta-${idx}`).querySelector('span')
              if(haschild){
                validado.push(false)
              }else{
                const span = document.createElement("span");
                span.innerHTML = 'Algo errado aqui. Verifique.'
                span.style.fontSize = '12px'
                span.style.color = 'red'
                span.style.marginTop = '.5em'
                document.querySelector(`#wrapper-pergunta-${idx}`).appendChild(span)    
                document.querySelector(`#wrapper-pergunta-${idx}`).classList.add('anim-shake') 
                validado.push(false)
              }  
            }
            perg.opcoes.length > 0 && perg.opcoes.map(op => {
              if(!op.texto || op.texto.trim() === ''){
                let haschild = document.querySelector(`#wrapper-pergunta-${idx}`).querySelector('span')
                if(haschild){
                  validado.push(false)
                }else{
                  const span = document.createElement("span");
                  span.innerHTML = 'Algo errado aqui. Verifique.'
                  span.style.fontSize = '12px'
                  span.style.color = 'red'
                  span.style.marginTop = '.5em'
                  document.querySelector(`#wrapper-pergunta-${idx}`).appendChild(span)    
                  document.querySelector(`#wrapper-pergunta-${idx}`).classList.add('anim-shake') 
                  validado.push(false)
                }  
              }
            })
            if(perg.opcoes.length === 0){
              let haschild = document.querySelector(`#wrapper-pergunta-${idx}`).querySelector('span')
              if(haschild){
                validado.push(false)
              }else{
                const span = document.createElement("span");
                span.innerHTML = 'Algo errado aqui. Verifique.'
                span.style.fontSize = '12px'
                span.style.color = 'red'
                span.style.marginTop = '.5em'
                document.querySelector(`#wrapper-pergunta-${idx}`).appendChild(span)    
                document.querySelector(`#wrapper-pergunta-${idx}`).classList.add('anim-shake') 
                validado.push(false)
              } 
            }
          }
          if(perg.tipo === 'aberta' || perg.tipo === 'data'){
            if(!perg.titulo || perg.titulo.trim() === ''){
              let haschild = document.querySelector(`#wrapper-pergunta-${idx}`).querySelector('span')
              if(haschild){
                validado.push(false)
              }else{
                const span = document.createElement("span");
                span.innerHTML = 'Algo errado aqui. Verifique.'
                span.style.fontSize = '12px'
                span.style.color = 'red'
                span.style.marginTop = '.5em'
                document.querySelector(`#wrapper-pergunta-${idx}`).appendChild(span)    
                document.querySelector(`#wrapper-pergunta-${idx}`).classList.add('anim-shake') 
                validado.push(false)
              }  
            }
          }
        })
      }
      if(this.currgadgettipo === 'contract'){
        if(!this.contract || this.contract.trim() === ''){
          validado.push(false)
          alertar('warning', '=/', 'Contrato Vazio')
        }
      }
      validado.includes(false) ? objretorno = false : objretorno = true
      return objretorno
    },
    limparErros(idx){
      document.querySelector(`#wrapper-pergunta-${idx}`).classList.remove('anim-shake') 
      document.querySelector(`#wrapper-pergunta-${idx}`).removeChild(document.querySelector(`#wrapper-pergunta-${idx}`).querySelector('span')) 
      //como remover um child voltar daqui
    },
    limparErrosExtra(idx){
      document.querySelector(`#wrapper-pergunta-extra-${idx}`).classList.remove('anim-shake') 
      document.querySelector(`#wrapper-pergunta-extra-${idx}`).removeChild(document.querySelector(`#wrapper-pergunta-extra-${idx}`).querySelector('span')) 
      //como remover um child voltar daqui
    },
    limparErrosVtext(e){
      this.erros[e.target.getAttribute('name')] = null
    },
    deletarPergunta(pergunta){
      const novoestado = this.perguntas.filter(el => el.id !== pergunta.id)
      this.perguntas = novoestado
    },
    deletarPerguntaExtra(pergunta){
      this.perguntas.map((perg, idxperg) => {
        perg.opcoes && perg.opcoes.map((op, idxop) => {
          op.perguntasextras.map(pergextra => {
            if(pergextra.id === pergunta.id){
              this.perguntas[idxperg].opcoes[idxop].perguntasextras = this.perguntas[idxperg].opcoes[idxop].perguntasextras.filter(el => el.id !== pergunta.id)
            }
          })
        })
      })

      const novoestado = this.perguntasextras.filter(el => el.id !== pergunta.id)
      this.perguntasextras = novoestado
    },
    vincular(pergunta, idx){
      const novoestado = this.vinculos
      novoestado.push(pergunta.id+idx)
      this.vinculos = novoestado
      this.respostaselecionada = pergunta.opcoes[idx]
    },
    desvincular(pergunta, idx){
      const novoestado = this.vinculos.filter(el => el !== pergunta.id+idx)
      this.vinculos = novoestado
      this.respostaselecionada = {
        text: null
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.input-opcao{
}
.modal-perguntasextras{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(240, 235, 248);
  z-index: 3;
  padding:2em;
}
.select-vincular{
  width: 9em;
  margin-left: 1em;
}
.wrapper-perguntas{
  display: flex;
  flex-wrap: wrap;
}
.wrapper-pergunta{
  padding: 1em;
  margin: 1em 0;
  border-radius: 30px;
  background: #ffffff;
  box-shadow:  0px 10px 20px rgba(128,128,128,.25);
  width: 100%;
  height: fit-content;
  @media screen and (max-width: 768px){
    width: 95%;
    display: flex;
    justify-self: center;

  }
  &:nth-child(2n){
    margin: 1em .5em;
  }
}
.pergunta-header{
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;
  min-width: 20em;
  &.full{
    width: 100% !important;
  }
  @media screen and (max-width: 768px){
    min-width: unset;
    width: 100%;
  }
}
  .btn-nova-opcao{
    padding: .5em 1em;
    background: #1377f2;
    border-radius: 10px;
    color:white;
    display: flex;
    align-items: center;
    width: fit-content;
    min-width: 7em;
    font-weight: bold;
    margin-top: .5em;
    @media screen and (max-width:768px){
      width: 4em;
      font-weight: lighter;
      font-size: 14px;
    }
  }

.field{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  >label{
    font-size: 12px;
    font-weight: bold;
    color: #aaa;
  }
  >textarea{
    width: 100%;
    outline: none;
  }


  .input-titulo{
    outline: none;
    height: 1.5em;
  }
}

.wrapper-opcao{
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: flex-start;
  input[type="text"]{
    outline: none;
    height: 1.5em;
    margin: .5em 0 .2em .5em;
    width: 40%;
  }
  .opcao-content{
    display: flex;
    align-items: center;
    height: 3em;
    width: 100%;
  }
}

.btn-perguntas-extras{
  margin-left: .5em;
}

.wrapper{
  width: 100%;
  min-height: 20em;
  height: auto;
  border: 1px solid #dadce0;
  border-radius: 8px;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  background-color: #f9f9f2;
  position: relative;
  margin-bottom: .7125em;
  transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1);
  -webkit-transition: box-shadow 280ms cubic-bezier(0.4,0,0.2,1);
  padding: 1.5em 1em;
  &:hover{
    box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
    cursor: pointer;
  } 
  .wrapper-form{
    display: flex;
    flex-direction: column;  
    position: relative;
    width: 100%;
    >div:nth-child(1){
      width: 100%;
      display: flex;
      align-items: center;
      .v-input{
        &:nth-child(1){
          margin-right: 1em;
          @media screen and (max-width: 768px){
            margin-right: unset;
          }
        }
      }
    }
    >div:nth-child(2){
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 1em;

    }
    >div:nth-child(4){
      display: flex;
      justify-content: flex-end;
      align-items: center;
      >button{
        padding: .5em 1em;
        background: #1377f2;
        border-radius: 10px;
        color:white;
        font-weight: bold;
        text-transform: capitalize;
      }
    }  
    
  }
  .wrapper-contract{
    width: 100%;
    display: flex;
    flex-direction: column;  
    position: relative;
    >div:nth-child(1){
      width: 100%;
      display: flex;
      align-items: center;
      .v-input:nth-child(1){
          margin-right: 1em;
          @media screen and (max-width: 768px){
            margin-right: unset;
          }
        }
    }
    >div:nth-child(3){
      display: flex;
      justify-content: flex-end;
      align-items: center;
      >button{
        margin-top: 1em;
        padding: .5em 1em;
        background: #1377f2;
        border-radius: 10px;
        color:white;
        font-weight: bold;
        text-transform: capitalize;
      }
    }
  }
  
}
      
      .wrapper-tipos-perguntas{
        @media screen and (max-width: 768px) {
          font-size: 14px;
        }
        >button{
          margin: 0 .5em;   
          text-decoration: underline;
          &:hover{
            color: #1377f2;
          }       
        }
      }
.btn-nova-pergunta{
        padding: .5em 1em;
        background: #1377f2;
        border-radius: 10px;
        color: white;
        text-transform: capitalize;
        z-index: 1;
        outline: none;
        transition: width 0.2s all;          
        @media screen and (max-width: 768px) {
          width: 100%;
          padding: .75em 1em;
        }
        &.elevation{
          z-index: 5 !important;
        }
      }

.button-group{
  background-color: #f9f9f2;
  border-radius: .5em;
  padding: 1em;
  margin-bottom: 1em;
  height: 4em;
    display: flex;
    align-items: center;
    justify-content: center;
  >button{
    outline: none;
    padding: 0.5em 1em;
    border-radius: 15px;
    background: whitesmoke;
    margin: 0 .5em;
    color: #1377f2;
    font-weight: bold;
    box-shadow: 0 10px 20px rgba(128,128,128,.25);
    transition: .1s ease-in-out;
    &:hover{
      border: 2px solid #1377f2;
    }
  }
}


.header{
  display: flex;
  padding: 1em;

  >h3{
    letter-spacing: 0.02em;
  }
  margin: 1em auto;
  background: rgba(215,211,222,0.5);
  height: fit-content;
  border-radius: 10px;
  >span{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.btn-voltar{
    padding: .75em 1em;
    line-height: 1.5;
    color: white;
    font-weight: bold;
    letter-spacing: 0.02em;
    background: #1377f2;
    border-radius: 10px;
    text-transform: capitalize;
  }

.btn{
  background-color: #1377F2;
  width: 100%;
  height: 3em !important;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.02em;
  padding: 1em;
  border-radius: 10px;
  font-weight: bold;
}

.anim-shake{
  animation: shake 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  transition: 1s ease-in-out;
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

//css indesejados
.v-input, .v-text-field{
  outline: none !important;
}
.v-text-field__details{
  display: none !important;
}

.col-sm-6, .col-12, .col-12{
  padding: unset !important;
}

.v-btn:not(.v-btn--round).v-size--default {
    min-width: unset !important;
    padding: unset !important;
}
.v-input--selection-controls {
    margin-top: unset !important;
    padding-top: unset !important;
}
</style>